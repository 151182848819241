<template>
  <div class="app-container h flex">
    <el-card header="经销商列表" shadow="never" class="no-flex" style="margin-right: 20px; width: 400px">
      <div class="head-container">
        <el-input v-model="query.nameOrErpId" clearable placeholder="ERP编号或企业简称" style="width: 200px" class="filter-item" @keyup.enter.native="toQuery">
          <el-button slot="append" icon="el-icon-search" size="mini" @click="toQuery"></el-button>
        </el-input>
        <!-- <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button> -->
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出经销商账号</el-button>
      </div>
      <el-table v-loading="loading" row-key="id" :data="data" size="small" highlight-current-row @current-change="handleCurrentChange" style="width: 100%">
        <el-table-column prop="erpId" label="ERP编号" width="120" />
        <el-table-column prop="name" label="企业简称" show-overflow-tooltip />
      </el-table>

      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </el-card>

    <el-tabs v-model="userRoleTab" type="border-card" class="v flex" >
      <el-tab-pane label="账号列表" name="users">
        <list :enterprise="current" />
      </el-tab-pane>
      <el-tab-pane label="角色列表" name="roles">
        <roleList :enterprise="current" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import list from "./list";
import roleList from "./roleList";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  components: { list, roleList },
  data() {
    return {
      userRoleTab: "users",
      downloadLoading: false,
      current: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributor";
      const sort = "id,desc";
      this.query.enabled = true;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    handleCurrentChange(row) {
      this.current = row;
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/users/distributor/download", this.params)
        .then((result) => {
          downloadFile(result, "经销商账号信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>