<template>
  <div class="v" style="height: 100%;">
    <template v-if="enterprise">
      <div class="head-container">      
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="initRole" :loading="initLoading">初始化角色</el-button>
      </div>

      <el-table v-loading="loading" :data="data" height="200px">
        <el-table-column prop="name" label="名称" min-width="120" />
            <el-table-column show-overflow-tooltip prop="remark" min-width="100" label="描述" />
            <el-table-column prop="createTime" label="创建日期" width="150">
              <template slot-scope="scope">
                <span>{{ new Date(scope.row.createTime).format() }}</span>
              </template>
            </el-table-column>
            <el-table-column label="用户列表" align="center" width="100">
              <template slot-scope="scope">
                <datagrid-picker reference="查看" reference-type="text" reference-size="mini" url="api/users" :params ="{roleId:scope.row.id}" :popper-width="400" :show-index="false" pageLayout="total,prev, pager, next,sizes" >
                <el-table-column prop="username" label="账号" />
                <el-table-column prop="userRealName" label="姓名" />
                <el-table-column prop="phone" label="电话" />
            </datagrid-picker>
              </template>
            </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </template>
    <div class="fc-g" v-else>请先在左侧选择要进行角色查看的经销商。</div>
    <eForm ref="form" :is-add="isAdd" />
    <reset-pwd ref="pwd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/user";
import eForm from "@/views/system/user/form";
import resetPwd from "@/views/system/user/reset-pwd";
import request from "@/utils/request";

export default {
  components: { eForm, resetPwd },
  mixins: [initData],
  props: {
    enterprise: Object,
  },
  data() {
    return {
      initLoading:false,
      query: {
        enabled: null,
        blurry: null,
      },
    };
  },
  computed: {
    title() {
      if (this.enterprise) return this.enterprise.name + " - 角色列表";
      return "角色列表";
    },
  },
  watch: {
    enterprise: "toQuery",
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (!this.enterprise) return false;
      this.url = "api/roles";
      this.params = {
        sort: "id,desc",
        enterpriseId: this.enterprise.enterpriseId,
      };
      return true;
    },
    
    add() {
      this.isAdd = true;
      this.$refs.form &&
        this.$refs.form.resetForm(
          null,
          this.enterprise.enterpriseId,
          null,
          "supplier"
        );
    },
    edit(data) {
      this.isAdd = false;
      let fd = JSON.parse(JSON.stringify(data));
      fd._roleIds = data.roles.map((r) => {
        return r.id;
      });
      this.$refs.form &&
        this.$refs.form.resetForm(
          fd,
          this.enterprise.enterpriseId,
          null,
          "supplier"
        );
    },
    initRole(){
      if(!this.enterprise.enterpriseId){
        this.$notify({
            title: "请选择经销商",
            type: "success",
            duration: 2500,
        });
        return;
      }
      this.$confirm(
        "您确定要该经销商初始化角色?",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        this.initLoading = true;
        request({
            url: 'api/roles/init/'+this.enterprise.enterpriseId,
            method: "post"
        }).then(_=>{
            this.init();
            this.$notify({
              title: "操作成功",
              type: "success",
              duration: 2500,
            });
        }).finally(_=>{
          this.initLoading = false;
        })
      });
    },
    resetPwd(data) {
      this.$refs.pwd && this.$refs.pwd.resetForm(data.username);
    },
    showRoles(data){      
      let n="";
      if(data.length>1){
        data.forEach(o => {
          n+=o.name+",";
        });
      }else if(data.length==1){
        data.forEach(o => {
          n=o.name;
        });
      }
      return n;
    },
  },
};
</script>